html {
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font: -apple-system-body;
  font-family: Montserrat, Roboto, sans-serif;
  overscroll-behavior-y: none;
}

a {
  color: inherit;
}
